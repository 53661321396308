import type { LinksFunction, LoaderArgs, MetaFunction } from '@remix-run/node';
import { json } from '@remix-run/node';
import {
  Links,
  LiveReload,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useLoaderData,
  useSearchParams,
} from '@remix-run/react';
import Nav from './components/Nav';

import { FilePreview } from './components/FilePreview';
import { UnknownErrorWidget } from './components/UnknownErrorWidget';
import { getUser } from './session.server';
import styles from './styles/styles.css';
import tailwindStylesheetUrl from './styles/tailwind.css';
import { getPages } from './models/pages.server';

export const links: LinksFunction = () => {
  return [
    { rel: 'stylesheet', href: tailwindStylesheetUrl },
    { rel: 'stylesheet', href: styles },
  ];
};

export const meta: MetaFunction = () => ({
  charset: 'utf-8',
  title: 'CSG',
  viewport: 'width=device-width,initial-scale=1',
});

export async function loader({ request }: LoaderArgs) {
  return json({
    user: await getUser(request),
    pages: await getPages(),
  });
}

export default function App() {
  const data = useLoaderData<typeof loader>();
  const [params] = useSearchParams();
  const showPreview = typeof params.get('preview') === 'string';
  const fileId = params.get('preview') ?? '';
  const fileName = params.get('preview_name') ?? '';
  const fileType = params.get('preview_type') ?? '';

  return (
    <html lang='en'>
      <head>
        <Meta />
        <Links />
      </head>
      <body className={'text-slate-700'}>
        <Nav pages={data.pages} />
        {showPreview && (
          <FilePreview
            file={{
              id: fileId,
              name: fileName,
              type: fileType,
            }}
          />
        )}
        <Outlet />
        <ScrollRestoration />
        <Scripts />
        <LiveReload />
      </body>
    </html>
  );
}

export const ErrorBoundary = ({ error, children }: { error: Error; children: React.ReactNode }) => {
  return (
    <html>
      <head>
        <title>Algo salió mal</title>
        <Meta />
        <Links />
      </head>
      <body>
        <Scripts />
        <LiveReload />
        <UnknownErrorWidget error={error} />
      </body>
    </html>
  );
};
